<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    <div class="row justify-content-between align-items-center">
        <div class="col-10">
            <h2>Welcome, {{account.user.firstname}}</h2>
        </div>
        <div class="col-1">
            <div class="navg2" *ngIf="isAdmin">
                <a class="snavlink" routerLink="/admin/overview" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Admin</a>
            </div>
            <div class="navg2" *ngIf="isManager">
                <a class="snavlink" routerLink="/admin/overview/accounts" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Manage Users</a>
            </div>
        </div>
        <div class="col-1">
            <div class="navg2">
                    <a class="snavlink" routerLink="/profile/details" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Profile</a>
            </div>
        </div>
    </div>
</div>

<div class="row"  style="margin-bottom: 10px;" *ngIf="account.user.role === 'manager' || this.account.user.role === 'admin'">
    <div class="col-lg-12">
        <div class="card table-card">
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="card-title">{{viewtitle}}</h3>
                </div>
            </div>
            
            <div class="row" style="height: 10px"></div>
            <div class="row">
                <table class="table table-striped table-borderless table-hover">
                    <thead>
                        <tr>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Year</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Employee</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Status</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Title</span>
                                </a>
                            </td>      
                                                                         
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Manager</span>
                                </a>
                            </td>

                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let review of dataService.reviews | paginate: { id: 'pagination1', itemsPerPage: itemsperpage, currentPage: currentpage, totalItems: totalrecords }" (click)="openReview(review)">       
                            <td> {{ review.year }} </td>
                            <td> {{ review.employee }} </td>
                            <td> {{ review.status }} </td>
                            <td> {{ review.title }} </td>

                            <td> {{ review.manager }} </td>
                        </tr>
                    </tbody>
                </table>
                <div class="cardFoot" *ngIf="totalrecords > itemsperpage">
                    <pagination-controls id="pagination1"  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>

<!--<div class="row"  style="margin-bottom: 10px;" *ngIf="isPM || isFinal">-->
<div class="row"  style="margin-bottom: 10px;" >
    <div class="col-lg-12">
        <div class="card table-card">
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="card-title">{{viewtitle2}}</h3>
                </div>
            </div>
            
            <div class="row" style="height: 10px"></div>
            <div class="row">
                <table class="table table-striped table-borderless table-hover">
                    <thead>
                        <tr>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Year</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Employee</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Status</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort">
                                    <span>Evaluator</span>
                                </a>
                            </td>    
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Manager</span>
                                </a>
                            </td>

                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let evaluation of dataService.evaluations | paginate: { id: 'pagination2', itemsPerPage: itemsperpage2, currentPage: currentpage2, totalItems: totalrecords2 }" (click)="openEvaluation(evaluation)">       
                            <td> {{ evaluation.year }} </td>
                            <td> {{ evaluation.employee }} {{evaluation.employee == evaluation.evaluator ? '(SELF)' : ''}}</td>
                            <td> {{ evaluation.evaluationstatus }} </td>
                            <td> {{ evaluation.evaluator }} </td>                            
                            <td> {{ evaluation.manager }} </td>
                        </tr>
                    </tbody>
                </table>
                <!--<div class="cardFoot" *ngIf="(this.account.user.role === 'manager' || this.account.user.role === 'admin') &&  totalrecords2 > itemsperpage2">-->
                <div class="cardFoot" *ngIf="totalrecords2 > itemsperpage2">
                    <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange2($event)"></pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>

