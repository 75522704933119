<div class="row noscreen">
</div>
<div class="row">
    <div class="card table-card">
        <div class="row justify-content-center">
            <div class="col-12 justify-content-center">
                <h2 *ngIf="!self">
                    FifthColor Employee Evaluation
                </h2>
                <h2 style="color:red" *ngIf="self">
                    FifthColor Employee Self Evaluation
                </h2>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-6 justify-content-between">
                <label>
                    Evaluator - {{dataService.evaluation.evaluator}}
                </label>
            </div>
            <div class="col-6 justify-content-between">
                <label>
                    Employee Name - {{dataService.evaluation.employee}}
                </label>
            </div>
        </div>

        <div class="row justify-content-between">
            <div class="col-6 justify-content-between">
                <label>
                    Status - {{dataService.evaluation.evaluationstatus}} ({{dataService.evaluation.evaluationdate | date: 'MM-dd-YYYY'}}) 
                </label>                
            </div>
            <div class="col-6 justify-content-between">
                <label>
                    Title - {{dataService.evaluation.title}}
                </label>                
            </div>                        
        </div>

        <div class="row justify-content-between">
            <div class="col-6 justify-content-between">
                <label>
                    Tenure - {{dataService.evaluation.tenure}}
                </label>                
            </div>
            <div class="col-6 justify-content-between">
                <label>
                    Tenure in Position - {{dataService.evaluation.tenureinposition}}
                </label>                
            </div>                        
        </div>

        <br>
        <div *ngIf="dataService.evaluation.evaluator === account.user.firstname + ' ' + account.user.lastname && dataService.evaluation.manager === account.user.firstname + ' ' + account.user.lastname" class="row justify-content-between">
            <div class="col-8 justify-content-end">
                <h5>Overall Rating</h5>
            </div>
            <div *ngIf="!editing" class="col-8 justify-content-end">
                {{dataService.evaluation.overallrating}}
            </div>
            <td *ngIf="editing">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Enter overall rating</mat-label>
                    <textarea matInput [(ngModel)]=dataService.evaluation.overallrating placeholder="Enter overall rating "></textarea>
                </mat-form-field>
            </td>
        </div>

        <br>
        <div class="row justify-content-between">
            <div class="col-8 justify-content-end">
                <h5>Performance Summary</h5>
            </div>
            <div class="col-4 align-buttons-right">
                <button class="btn btn-primary spacer-r-5" *ngIf="!editing && dataService.evaluation.evaluationstatus==='draft'" (click)="editEval()">Edit</button>
                <button class="btn btn-info spacer-r-5" *ngIf="editing && dataService.evaluation.evaluationstatus!='submitted'" (click)="saveEval('Draft saved')">Save as Draft</button>
                <button class="btn btn-primary spacer-r-5" *ngIf="editing && dataService.evaluation.evaluationstatus!='submitted'" (click)="submitEval()">Submit</button>
                <button class="btn btn-success spacer-r-5" *ngIf="dataService.evaluation.evaluationstatus==='submitted' && dataService.evaluation.evaluator === account.user.firstname + ' ' + account.user.lastname && dataService.evaluation.manager === account.user.firstname + ' ' + account.user.lastname" (click)="markComplete()">Mark as Complete</button>
                <button class="btn btn-danger spacer-r-5" *ngIf="editing" (click)="cancelEval()">Cancel</button>
                <button class="btn btn-info spacer-r-5" *ngIf="canReset" (click)="backToDraft()">Reset to Draft</button>
                <button class="btn btn-danger spacer-r-5" *ngIf="!editing" (click)="back()">Back</button>
            </div>
        </div>

        <div class="row justify-content-between spacer-t-5">
 


            <table class="table table-striped table-borderless">
                <thead style="background-color:rgb(142, 218, 243)">
                    <tr>
                        <td scope="col" class="col-1">
                            <span>#</span>
                        </td>
                        <td scope="col" class="col-4">
                            <span>Question</span>
                        </td>
                        <td scope="col" class="col-7">
                            <span>Answer</span>
                        </td>                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let question of dataService.summaryquestions; index as i" (click)="openEvaluation(eval)">       
                        <td>{{ question.id }}</td>
                        <td>{{ question.qtext }}</td>

                        <td *ngIf="!editing">{{ question.atext }}</td>

                        <td *ngIf="editing">

                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Enter details</mat-label>
                                <textarea matInput [(ngModel)]=dataService.summaryquestions[i].atext placeholder="Ex. "></textarea>
                            </mat-form-field>
                        </td>

                    </tr>
                </tbody>
            </table>

            <table class="table table-striped table-borderless">
                <ng-template let-question ngFor [ngForOf]="dataService.questions"  [ngForTrackBy]="trackHeaders">
                    <thead *ngIf="question.qheader!='X'">
                        <tr>
                            <td></td>
                        </tr>
                        <tr  style="background-color:rgb(142, 218, 243)">    
                            <td>{{ question.qheader }}</td>
                            <td  *ngFor="let category of dataService.questioncategories; index as i">
                                {{category.name}}
                            </td>


                        </tr>
                    </thead>
                    <tbody>
                        <!--<span *ngFor="let question of dataService.questions; index as i">-->
                           
                            <tr>       
                                <td>{{ question.qtext }}</td>
                                <td>{{ question["1"] }}</td>
                                <td>{{ question["2"] }}</td>
                                <td>{{ question["3"] }}</td>
                                <td>{{ question["4"] }}</td>
                                <td>{{ question["5"] }}</td>
                                <td>{{ question["6"] }}</td>


                            </tr>
                            <tr *ngIf="!editing">

                                <td></td>   
                                <td style="text-align: center">{{question.answer=="1" ? "X" : "&nbsp;"}}</td>
                                <td style="text-align: center">{{question.answer=="2" ? "X" : "&nbsp;"}}</td>
                                <td style="text-align: center">{{question.answer=="3" ? "X" : "&nbsp;"}}</td>
                                <td style="text-align: center">{{question.answer=="4" ? "X" : "&nbsp;"}}</td>
                                <td style="text-align: center">{{question.answer=="5" ? "X" : "&nbsp;"}}</td>
                                <td style="text-align: center">{{question.answer=="6" ? "X" : "&nbsp;"}}</td>

                            </tr>
                            <tr *ngIf="editing">
                                <td></td>

                                <td style="text-align: center" *ngFor="let q of qs">
                                    <mat-radio-group
                                    aria-labelledby="example-radio-group-label"
                                    class="example-radio-group"
                                    [(ngModel)]=question.answer>                                        
                                        <mat-radio-button class="example-radio-button"  [value]="q">
                                                
                                        </mat-radio-button>                            
                                    </mat-radio-group>
                                </td>

                                <!--
                                <mat-radio-group
                                    aria-labelledby="example-radio-group-label"
                                    class="example-radio-group"
                                    [(ngModel)]="favoriteSeason">
                                    <td *ngFor="let season of seasons">
                                        <mat-radio-button class="example-radio-button"  [value]="season">
                                            {{season}}                                    
                                        </mat-radio-button>
                                    </td>
                                </mat-radio-group>
                            -->
                                
                            </tr>       
                            <tr *ngIf="showComment(question.id)">       
                                <td>Comments:</td>
                                <td *ngIf="!editing" colspan=5>{{dataService.comments[commentCount-1].qcomment}}</td>

                                <td *ngIf="editing"  colspan=5>
        
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <mat-label>Comments</mat-label>
                                        <!--<textarea id={{commentCount}} matInput [(ngModel)]=dataService.comments[commentCount-1].qcomment placeholder="Ex. "></textarea>-->
                                        <textarea id="Comment{{commentCount}}" matInput value={{dataService.comments[commentCount-1].qcomment}} placeholder="Ex. "></textarea>
                                    </mat-form-field>
                                </td>

                                
                            </tr>

                    </tbody>
                </ng-template>
            </table>            
        </div>   
        <br>
        <div class="row justify-content-between">
            <div class="col-8 justify-content-end">
                
            </div>
<!--            <div class="col-4 align-buttons-right">
                <button class="btn btn-primary spacer-r-5" *ngIf="!editing && dataService.evaluation.evaluationstatus==='draft'" (click)="editEval()">Edit</button>
                <button class="btn btn-info spacer-r-5" *ngIf="editing && dataService.evaluation.evaluationstatus!='submitted'" (click)="saveEval()">Save as Draft</button>
                <button class="btn btn-primary spacer-r-5" *ngIf="editing && dataService.evaluation.evaluationstatus!='submitted'" (click)="submitEval()">Submit</button>
                <button class="btn btn-success spacer-r-5" *ngIf="dataService.evaluation.evaluationstatus==='submitted' && dataService.evaluation.evaluator === account.user.firstname + ' ' + account.user.lastname" (click)="markComplete()">Mark as Complete</button>
                <button class="btn btn-danger spacer-r-5" *ngIf="editing" (click)="cancelEval()">Cancel</button>
                <button class="btn btn-danger spacer-r-5" *ngIf="!editing" (click)="back()">Back</button>
            </div>
        -->
            <div class="col-4 align-buttons-right">
                <button class="btn btn-primary spacer-r-5" *ngIf="!editing && dataService.evaluation.evaluationstatus==='draft'" (click)="editEval()">Edit</button>
                <button class="btn btn-info spacer-r-5" *ngIf="editing && dataService.evaluation.evaluationstatus!='submitted'" (click)="saveEval('Draft saved')">Save as Draft</button>
                <button class="btn btn-primary spacer-r-5" *ngIf="editing && dataService.evaluation.evaluationstatus!='submitted'" (click)="submitEval()">Submit</button>
                <button class="btn btn-success spacer-r-5" *ngIf="dataService.evaluation.evaluationstatus==='submitted' && dataService.evaluation.evaluator === account.user.firstname + ' ' + account.user.lastname && dataService.evaluation.manager === account.user.firstname + ' ' + account.user.lastname" (click)="markComplete()">Mark as Complete</button>
                <button class="btn btn-danger spacer-r-5" *ngIf="editing" (click)="cancelEval()">Cancel</button>
                <button class="btn btn-danger spacer-r-5" *ngIf="!editing" (click)="back()">Back</button>
            </div>            
        </div>     

    </div>
</div>


