<div class="row noscreen">
</div>
<div class="row noprint">
    <div class="card table-card">
        <div class="row justify-content-between">
            <div class="col-10 justify-content-between">
                <h2>
                    Employee Reviews
                </h2>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-10 justify-content-between">
                <label>
                    {{dataService.review.employee}} - {{dataService.review.title}}
                </label>
            </div>
            <div class="col-2 justify-content-between">
                <label>
                    Status - {{dataService.review.status}}
                </label>                
            </div>

        </div>
        <div class="row justify-content-between">
            <div class="col-10 justify-content-between">
                <label>
                    Manager - {{dataService.review.manager}}
                </label>
            </div>
            <div class="col-2 justify-content-between">
                <label>
                    Year - {{dataService.review.year}}
                </label>
            </div>
        </div>            
        <br>
        <div class="row justify-content-between">
            <h5>Evaluations</h5>
        </div>

        <div class="row justify-content-between">
 


            <table class="table table-striped table-borderless">
                <thead style="background-color:rgb(142, 218, 243)">
                    <tr>
                        <td scope="col" class="col-3">
                            <span>#</span>
                        </td>
                        <td scope="col" class="col-3">
                            <span>Evaluator</span>
                        </td>
                        <td scope="col"  class="col-3">
                            <span>Status</span>
                        </td>
                        <td scope="col"  class="col-3">
                            <span>Evaluation Date</span>
                        </td>                        

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let eval of dataService.review.evaluations; index as i" (click)="openEvaluation(eval)">       
                        <td>{{ i+1 }}</td>
                        <td>{{eval.evaluator}} {{eval.evaluator === dataService.review.employee ? '(self)' : ''}} {{eval.evaluator === dataService.review.manager ? '(manager)' : ''}}</td>
                        <td>{{eval.status}}</td>
                        <td>{{eval.evaluationdate | date: 'MM/dd/YYY'}}</td>
                    </tr>
                </tbody>
            </table>

        </div>        
    </div>
</div>

