import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AccountService } from '@app/_services';
import { ReviewsService } from '@app/_services';
import { Account } from '@app/_models';
import { FormsModule } from '@angular/forms';

@Component({ templateUrl: 'list.component.html' })
export class ListComponent implements OnInit {
    accounts: any[];
    selectedAccounts: any[];
    checked = false;
    screen = 1;
    reviewYear = '2022';
    account = this.accountService.accountValue;

    isAdmin: boolean;
    isManager: boolean;

    constructor(private accountService: AccountService,
                private reviewsService: ReviewsService
        )
     {}

    ngOnInit() {
     
        this.isAdmin = this.account.user.role === 'admin';
        this.isManager = this.account.user.role === 'manager';


        if (this.isAdmin) {
            this.accountService.getAll()
            .pipe(first())
            .subscribe(accounts => this.accounts = accounts);
        } else {
            this.accountService.getByManager(this.account.user.id)
                .pipe(first())
                .subscribe(accounts => this.accounts = accounts);
        }
    }

    deleteAccount(id: string) {
        const account = this.accounts.find(x => x.id === id);
        account.isDeleting = true;
        this.accountService.delete(id)
            .pipe(first())
            .subscribe(() => {
                this.accounts = this.accounts.filter(x => x.id !== id) 
            });
    }

    queueReviews() {
        this.selectedAccounts = [];
        this.accounts.forEach(element => {
            let ele = document.getElementById(element.id);
            if (ele['checked']) {
                console.log(`${element.id} - ${element.firstname} ${element.lastname}`);
                element.status = 'Ready';
                element.color = 'gray';
                this.selectedAccounts.push(element);

            }
        });
        this.screen = 2;
    }

    createReviews() {
        debugger;

        this.selectedAccounts.forEach(element => {

            // is there an existing review for this year?
            var review = this.reviewsService.getReviewByYear(element.id, this.reviewYear)
                .pipe(first())
                .subscribe((x: any) => {
                    if (x) {
                        element.status = 'Review already exists';
                        element.color = 'gray';
                        // create an evaluation for each evaluator (if it doesn't exist)
                        var evaluators = this.reviewsService.getEvaluatorsNoEvaluation(element.id, x.id)
                            .pipe(first())
                            .subscribe((xxxx: any) => {

                                xxxx.forEach(element2 => {
                                    var body = {
                                        reviewid: x.id,
                                        evaluatorid: element2.id,
                                        status:'draft'
                                    };    
                                    
                                    review = this.reviewsService.addEvaluation(body)
                                        .pipe(first())
                                        .subscribe((xxx: any) => {
                                            this.addComments(xxx.insertId);
                                            this.addSummaries(xxx.insertId);
                                            this.addAnswers(xxx.insertId);
;
                                            element.status = 'Review already exists - new evals added';
                                            element.color = 'gray';
                                    });
                                });
                            });
                    } else {

                        review = this.reviewsService.addReview(element.id, this.reviewYear)
                            .pipe(first())
                            .subscribe((xx: any) => {

                                var evaluators = this.reviewsService.getEvaluators(element.id)
                                    .pipe(first())
                                    .subscribe((xxxx: any) => {

                                        xxxx.forEach(element2 => {
                                            var body = {
                                                reviewid: xx.insertId,
                                                evaluatorid: element2.id,
                                                status:'draft'
                                            };    
                                            
                                            review = this.reviewsService.addEvaluation(body)
                                                .pipe(first())
                                                .subscribe((xxx: any) => {
                                                    this.addComments(xxx.insertId);
                                                    this.addSummaries(xxx.insertId);
                                                    this.addAnswers(xxx.insertId);

                                                    element.status = 'Success!';
                                                    element.color = 'green';
                                            });
                                        });

                                        // self evaluation
                                        var body = {
                                            reviewid: xx.insertId,
                                            evaluatorid: element.id,
                                            status:'draft'
                                        };    
                                        
                                        review = this.reviewsService.addEvaluation(body)
                                            .pipe(first())
                                            .subscribe((xxx: any) => {
                                                this.addComments(xxx.insertId);
                                                this.addSummaries(xxx.insertId);
                                                this.addAnswers(xxx.insertId);
                                        });

                                        // manager evaluation
                                        var body = {
                                            reviewid: xx.insertId,
                                            evaluatorid: element.manager,
                                            status:'draft'
                                        };    
                                        
                                        review = this.reviewsService.addEvaluation(body)
                                            .pipe(first())
                                            .subscribe((xxx: any) => {
                                                this.addComments(xxx.insertId);
                                                this.addSummaries(xxx.insertId);
                                                this.addAnswers(xxx.insertId);
                                        });

                                    });


                               
                            })
                    }
            });
        });



        this.screen = 2;

    }

    // add the comments answers
    addComments(evalid) {
        this.reviewsService.addComments({evaluationid: evalid, qheader: 'Inspiring Others', qorder: 1}).subscribe();
        this.reviewsService.addComments({evaluationid: evalid, qheader: 'Driving Results', qorder: 2}).subscribe();
        this.reviewsService.addComments({evaluationid: evalid, qheader: 'Delivering Expertise', qorder: 3}).subscribe();
        this.reviewsService.addComments({evaluationid: evalid, qheader: 'Creating Solutions', qorder: 4}).subscribe();
    }

    // add the summary answers
    addSummaries(evalid) {
        this.reviewsService.addSummary({evaluationid: evalid, qid: 1}).subscribe();
        this.reviewsService.addSummary({evaluationid: evalid, qid: 2}).subscribe();
        this.reviewsService.addSummary({evaluationid: evalid, qid: 3}).subscribe();
        this.reviewsService.addSummary({evaluationid: evalid, qid: 4}).subscribe();
    }    

    // add the answers
    addAnswers(evalid) {

        for (let i = 1; i <= 16; i++) {
            this.reviewsService.addAnswer({evaluationid: evalid, qid: i}).subscribe();
        }

    }        
}