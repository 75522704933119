import { Review } from '.';

export class Reviews {
    count: number;
    limit: number;
    page: number;
    totalPages: number;
    offset: number;
    results: [
        Review
    ]
}