import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AccountService, LoadingService} from './_services';
import { Account, Role } from './_models';
import { DataService } from '@app/_services';
import { QueryOptions } from '@app/_models/query-opts';
import { takeUntil, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

//import { DatePickerComponent } from './date-picker/date-picker.component';
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import moment, { Moment } from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    providers: []
})


export class AppComponent implements OnInit
{
    collapsed: boolean = true;
    Role = Role;
    account: Account;
    loading: boolean = false;
    searchValue = '';
    isFinal: boolean;
    envMessage: string = '';
    version: string = '';
    queryOptions: QueryOptions = new QueryOptions();
    canComplete = false;
    canAdd = false;
    private leadSub = new Subject(); 
    opens = 'left';
    ranges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ],
        'Last 3 Month': [
          moment()
            .subtract(3, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ]
      };


    constructor(private accountService: AccountService,
                private loadingService: LoadingService,
                public dataService: DataService,
                private route: ActivatedRoute,
                private cookieService: CookieService,
                private router: Router) 
        {
            this.queryOptions.page = 1;
            this.queryOptions.pageSize = 5;
            this.accountService.account.subscribe(x => this.account = x);
            this.dataService.isFinal = false;
            if (this.account) {
              this.dataService.isFinal = this.account.user.role === 'approver';
              this.dataService.canComplete = this.account.user.role === 'approver' || this.account.user.role === 'purchasingmanager' || this.account.user.role === 'receiving';
              this.dataService.canAdd = this.account.user.role !== 'receiving';
            }

            this.version = environment.version;
            this.envMessage = environment.message;

        }

    logout() {
      this.collapse();  
      this.accountService.logout();
    }

    expand() {
      debugger;

      var st: any;
      var mn: any;

      st = document.querySelector(".sidenav");
      mn = document.querySelector(".main");

      
        st.style.width = "20%";
        mn.style.width = "80%";
        mn.style['margin-left'] = "20%";
        this.collapsed = false;
        
        this.cookieService.set('collapsed', 'false');
        return false;
    }

    collapse() {
      debugger;

      var st: any;
      var mn: any;

      st = document.querySelector(".sidenav");
      mn = document.querySelector(".main");

      st.style.width = "5%";
      mn.style.width = "95%";
      mn.style['margin-left'] = '5%';
      this.collapsed = true;

      this.cookieService.set('collapsed', 'true');

      return false;

    }

    showOrders() {
      this.dataService.review = null;
      this.router.navigate(['/dashboard/reviews'], { relativeTo: this.route })
      return false;
    }

    showAllReviews() {
      //this.dataService.review = null;
      this.router.navigate(['/reviews/reviews'], { relativeTo: this.route })
      return false;
    }    



    addOrder() {

      this.dataService.review = null;
      this.router.navigate(['/orders/order'], { relativeTo: this.route })
      return false;
    }

    ngOnInit(): void{
        this.listenToLoading();


        //this.dataService.startDate = localStorage.getItem('startDate');
        //this.dataService.endDate = localStorage.getItem('endDate');

        //this.dataService.selectedDates = { startDate: moment(this.dataService.startDate), endDate: moment(this.dataService.endDate) }



    }
    ngAfterViewInit() {
//      var collapsed = localStorage.getItem('collapsed');
        //this.collapse();
        /*
      var collapsed = this.cookieService.get('collapsed');
      
      if (collapsed && collapsed === 'true') {
          this.collapse();
      }
      */
    }



    listenToLoading(): void{
        this.loadingService.loadingSub
            .pipe()
            .subscribe((loading: boolean) => {
                this.loading = loading;
            });
    }

        /* Get the top 5 leads*/
       // this.leadService.getLeads(this.queryOptions, this.account.currentorg.org_id).subscribe(leads => this.companyLeads = leads);


    

}