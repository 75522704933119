<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    <div class="row justify-content-between align-items-center">
        <div class="col-10">
            <h2>All Reviews</h2>
        </div>
        <div class="col-1">
            <div class="navg2" *ngIf="isAdmin">
                <a class="snavlink" routerLink="/admin/overview" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Admin</a>
            </div>
            <div class="navg2" *ngIf="isManager">
                <a class="snavlink" routerLink="/admin/overview/accounts" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Manage Users</a>
            </div>
        </div>
        <div class="col-1">
            <div class="navg2">
                    <a class="snavlink" routerLink="/profile/details" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Profile</a>
            </div>
        </div>
    </div>
</div>

<div class="row"  style="margin-bottom: 10px;">
    <div class="col-lg-12">
        <div class="card table-card">
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="card-title">{{viewtitle}}</h3>
                </div>
            </div>
            
            <div class="row" style="height: 10px"></div>
            <div class="row">
                <table class="table table-striped table-borderless table-hover">
                    <thead>
                        <tr>
                            <td scope="col">
                                <span>Year</span>
                            </td>
                            <td scope="col">
                                    <span>Employee</span>
                            </td>
                            <td scope="col">
                                    <span>Status</span>
                            </td>
                            <td scope="col">
                                    <span>Title</span>
                            </td>                            
                            <td scope="col">
                                    <span>Manager</span>
                            </td>
                            <td scope="col">
                                    <span>Overall Rating</span>
                            </td>                            

                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let review of dataService.reviews | paginate: { id: 'pagination1', itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords }" (click)="openReview(review)">       
                            <td> {{ review.year }} </td>
                            <td> {{ review.employee }} </td>
                            <td> {{ review.status }} </td>
                            <td> {{ review.title }} </td>
                            <td> {{ review.manager }} </td>
                            <td> {{ review.overallrating }} </td>
                        </tr>
                    </tbody>
                </table>
<!--                <div class="cardFoot">
                    <pagination-controls id="pagination1"  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                </div>-->
            </div>

        </div>
    </div>
</div>
