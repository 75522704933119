import { Component, OnInit , Input} from '@angular/core';
import { ReviewsService } from '../../_services/reviews.service';
import { AccountService } from '@app/_services';
import { DataService } from '@app/_services';
import { Reviews } from '../../_models';
import { QueryOptions } from '@app/_models/query-opts';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';

@Component({ 
    templateUrl: 'allreviews.component.html',
    providers: [ReviewsService]
})


export class AllReviewsComponent implements OnInit {
    itemsperpage: number;
    currentpage: number;
    totalrecords: number;
    pageSize: number = 10;
    page: number = 1;
    totalPages: number;

    account = this.accountService.accountValue;
    reviews: Reviews;
    title: string;
    timestamp: string;
    datestamp: string;
    reviews$: Observable<Reviews>;
    viewtitle = "All Reviews";
    isAdmin: boolean;
    isManager: boolean;

    queryOptions: QueryOptions = new QueryOptions();
    queryOptions2: QueryOptions = new QueryOptions();

    constructor(private accountService: AccountService,
            public dataService: DataService,
            private reviewsService: ReviewsService,
            private route: ActivatedRoute,
            private router: Router
        ) {
        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 5;
        this.queryOptions.sortDirection = 'desc';
     }
   

    ngOnInit(): void {
        this.itemsperpage = 150;
        this.currentpage = 1;

        localStorage.removeItem('review'); 
        localStorage.removeItem('evaluation'); 
        
        this.isAdmin = this.account.user.role === 'admin';
        this.isManager = this.account.user.role === 'manager';


        
        var term: string;
        var objValue: string;
        debugger;

        if (this.isAdmin) {
            this.loadAllReviews();
        } else {
            this.loadAllManagerReviews();
        }

    }




    ngOnDestroy(): void {        

    }

    pageSizeChange(event) {
        this.pageSize = event;
      }
    
    handlePageChange(event) {
    this.queryOptions.page = event;
    this.queryOptions.pageSize = this.pageSize;
    this.currentpage = event;
    // this.refreshLeads(this.queryOptions.sortOrder);  
    }

    loadAllReviews() {
        this.queryOptions.sortOrder = 'year';
        this.queryOptions.sortDirection = 'desc';
        this.queryOptions.pageSize = 1000000;

        //this.reviewsService.getReviews(this.queryOptions, this.account.user.id).pipe(
        this.reviewsService.getReviews(this.queryOptions).pipe(
            map(reviews => {
                    this.dataService.reviews = reviews;
                    this.totalrecords = this.dataService.reviews.length;
            }),
        ).subscribe();
    }   

    loadAllManagerReviews() {
        this.queryOptions.sortOrder = 'year';
        this.queryOptions.sortDirection = 'desc';
        this.queryOptions.pageSize = 1000000;

        this.reviewsService.getManagerReviews(this.queryOptions, this.account.employees).pipe(
            map(reviews => {
                    this.dataService.reviews = reviews;
                    this.totalrecords = this.dataService.reviews.length;
            }),
        ).subscribe();
    }   

    sortLeads(sortOrder) {
    }

    openReview(review) {
        this.dataService.review = review;
        this.router.navigate(['/reviews/review'], { relativeTo: this.route })
      }


}