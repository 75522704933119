<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    <div class="row justify-content-between align-items-center">
        <div class="col-10">
            <h2>Manage Users</h2>
        </div>
        <div class="col-1">
            <div class="navg2" *ngIf="isAdmin">
                <a class="snavlink" routerLink="/admin/overview" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Admin</a>
            </div>
            <div class="navg2" *ngIf="isManager">
                <a class="snavlink" routerLink="/admin/overview/accounts" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Manage Users</a>
            </div>
        </div>
        <div class="col-1">
            <div class="navg2">
                    <a class="snavlink" routerLink="/profile/details" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Profile</a>
            </div>
        </div>
    </div>
</div>
<div *ngIf="screen===1">
    
    <form>
        <a routerLink="add" class="btn btn-sm btn-success m-2" *ngIf="isAdmin">Create Account</a>
        <a (click)="queueReviews()" class="btn btn-sm btn-info">Create Reviews</a>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width:10%"></th>
                    <th style="width:30%">Name</th>
                    <th style="width:15%">Email</th>
                    <th style="width:15%">Manager</th>
                    <th style="width:20%">Roles</th>
                    <th style="width:10%"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let account of accounts">
                
                    <td>
                        <input type="checkbox" id="{{account.id}}"  >
                    </td>
                    <td>{{account.firstname}} {{account.lastname}}</td>
                    <td>{{account.email}}</td>
                    <td>{{account.managername}}</td>
                    <td>{{account.role}}</td>
                    <td style="white-space: nowrap">
                        <a routerLink="edit/{{account.id}}" class="btn btn-sm btn-primary mr-1 spacer-r-5">Edit</a>
                        <!--<button (click)="deleteAccount(account.id)" class="btn btn-sm btn-danger btn-delete-account" [disabled]="account.isDeleting">
                            <span *ngIf="account.isDeleting" class="spinner-border spinner-border-sm"></span>
                            <span *ngIf="!account.isDeleting">Delete</span>
                        </button>-->
                    </td>
                </tr>
                <tr *ngIf="!accounts">
                    <td colspan="4" class="text-center">
                        <span class="spinner-border spinner-border-lg align-center"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
<div *ngIf="screen===2">

    
    <p>The folllowing reviews will be created for 
        <select [(ngModel)]="reviewYear">
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
        </select>
        <a (click)="createReviews()" class="btn btn-sm btn-info m-2">Create</a>
    </p>
    <form>
        
        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width:30%">Name</th>
                    <th style="width:30%">Email</th>
                    <th style="width:30%">Manager</th>
                    <th style="width:10%">Status</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let account of selectedAccounts">
                

                    <td>{{account.firstname}} {{account.lastname}}</td>
                    <td>{{account.email}}</td>
                    <td>{{account.managername}}</td>
                    <td style="color: {{account.color}}"> {{account.status}}</td>
                </tr>
               
            </tbody>
        </table>
    </form>
</div>