<div>
  <h1 mat-dialog-title>{{dataService.confirmationTitle}}</h1>
  <div class="row">
    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <h3 mat-dialog-title>{{dataService.confirmationText}}</h3>
      </mat-dialog-content>
    </div>
  </div>
  <div class="row">
      <div class="col-4"></div>
      <div class="col-2">
        <button class="btn btn-primary" (click)="onClick(1)">Yes</button>
      </div>
      <div class="col">
        <button class="btn btn-danger" (click)="onClick(0)">No</button>
      </div>
  </div>
</div>

