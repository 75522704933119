<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    <div class="row justify-content-between align-items-center">
        <div class="col-10">
            <h2>Welcome, {{account.user.firstname}}</h2>
        </div>
        <div class="col-1">
            <div class="navg2" *ngIf="isAdmin">
                <a class="snavlink" routerLink="/admin/overview" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Admin</a>
            </div>
            <div class="navg2" *ngIf="isManager">
                <a class="snavlink" routerLink="/admin/overview/accounts" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Manage Users</a>
            </div>
        </div>
        <div class="col-1">
            <div class="navg2">
                    <a class="snavlink" routerLink="/profile/details" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Profile</a>
            </div>
        </div>
    </div>
</div>


    <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">Details</mat-icon>
          </ng-template>

          <form [formGroup]="form" (ngSubmit)="saveBasic()">
                <div class="form-row">
                    <div class="form-group col-5">
                        <label>First Name</label>
                        <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                        <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                            <div *ngIf="f.firstname.errors.required">First Name is required</div>
                        </div>
                    </div>
                    <div class="form-group col-5">
                        <label>Last Name</label>
                        <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                        <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                            <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-7">
                        <label>Email</label>
                        <input type="text" autocomplete="off" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                    
                    <div class="form-group col-7">
                        <label>Manager</label>
                        <input disabled type="text" autocomplete="off" formControlName="managername" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.managername.errors }" />
                        <div *ngIf="submitted && f.managername.errors" class="invalid-feedback">
                            <div *ngIf="f.managername.errors.required">Manager is required</div>
                        </div>
                        
                    </div>
                    <div class="form-group col-2">
                        <button class="btn btn-info" (click)="chooseManager()">Lookup</button>
                    </div>
                    <div class="form-group col">
                        <label>Role</label>
                        <select formControlName="role" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                            <option value=""></option>
                            <option value="employee">employee</option>
                            <option value="admin">admin</option>
                        </select>
                        <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                            <div *ngIf="f.role.errors.required">Role is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label>Password</label>
                        <input autocomplete="off" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                    </div>
                    <div class="form-group col">
                        <label>Confirm Password</label>
                        <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                            <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary spacer-r-5">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                    <a routerLink="/admin/accounts" class="btn btn-danger">Cancel</a>
                </div>
               
            </form>
        </mat-tab>
      
       
        <mat-tab *ngIf="!isAddMode">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">Evaluators</mat-icon>
            
          </ng-template>
      
          <br><br>
          Click choose to select evaluators to provide feedback
          <br>
          <button class="btn btn-primary" (click)="chooseEvaluators()">Choose</button>
          <br><br>
          <div>
            <form>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width:90%">Evaluator</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let evaluator of dataService.evaluators">
                            <td>{{evaluator.firstname}} {{evaluator.lastname}}</td>
                        </tr>
                        <tr *ngIf="!dataService.evaluators">
                            <td colspan="4" class="text-center">
                                <span class="spinner-border spinner-border-lg align-center"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary spacer-r-5" (click)="saveEvaluators()">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                    <a routerLink="/admin/accounts" class="btn btn-danger">Cancel</a>
                </div>
            </form>
        </div>
        </mat-tab>

        <mat-tab *ngIf="!isAddMode">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">Evaluations</mat-icon>
              
            </ng-template>
        
            <br><br>
            <div>
              <form>
                  <table class="table table-striped">
                      <thead>
                          <tr>
                              <th style="width:25%">Evaluator</th>
                              <th style="width:25%">Manager</th>
                              <th style="width:25%">Year</th>
                              <th style="width:25%">Status</th>
                              
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let evaluation of dataService.evaluations">
                              <td>{{evaluation.evaluator}}</td>
                              <td>{{evaluation.manager}}</td>
                              <td>{{evaluation.year}}</td>
                              <td>{{evaluation.evaluationstatus}}</td>
                          </tr>
                          <tr *ngIf="!dataService.evaluators">
                              <td colspan="4" class="text-center">
                                  <span class="spinner-border spinner-border-lg align-center"></span>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </form>
          </div>
          </mat-tab>        
      </mat-tab-group>

      