import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { DashboardComponent } from './reviews/dashboard';
import { AllReviewsComponent } from './reviews/reviews';
import { ReviewComponent } from './reviews/review';
import { EvaluationComponent } from './reviews/evaluation';


import { OverviewComponent } from '@app/admin/overview.component';
import { ListComponent } from '@app/admin/accounts/list.component';
import { AddEditComponent } from '@app/admin/accounts/add-edit.component';

import { LayoutComponent } from '@app/profile/layout.component';
import { DetailsComponent } from '@app/profile/details.component';
import { UpdateComponent } from '@app/profile/update.component'; 
    
const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const profileModule = () => import('./profile/profile.module').then(x => x.ProfileModule);

const routes: Routes = [
    { path: 'reviews/dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'reviews/review', component: ReviewComponent, canActivate: [AuthGuard] },
    { path: 'reviews/reviews', component: AllReviewsComponent, canActivate: [AuthGuard] },
    { path: 'reviews/evaluation', component: EvaluationComponent, canActivate: [AuthGuard] },
    { path: 'admin/overview', component: OverviewComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'manager'] }  },
    { path: 'admin/overview/accounts', component: ListComponent, canActivate: [AuthGuard], data: { roles: ['admin', 'manager'] }  },
    { path: 'admin/overview/accounts/edit/:id', component: AddEditComponent,  canActivate: [AuthGuard] },
    { path: 'admin/overview/accounts/add', component: AddEditComponent,  canActivate: [AuthGuard] },
    { path: 'profile', component: LayoutComponent },
    { path: 'profile/details', component: DetailsComponent,  canActivate: [AuthGuard] },
    { path: 'profile/update', component: UpdateComponent },
    { path: 'account', loadChildren: accountModule },


    // otherwise redirect to home
    { path: '',   redirectTo: 'reviews/dashboard', pathMatch: 'full' },
    { path: '**', redirectTo: 'account/login', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false /*, relativeLinkResolution: 'legacy'*/ })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
