import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Reviews } from '@app/_models';
import { Review } from '@app/_models';
import { QueryOptions } from '@app/_models';
import { DateRangeService } from './daterange.service';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class ReviewsService{
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private httpClient: HttpClient, private dateRangeService: DateRangeService){ }

    getReviews(queryOpts: QueryOptions): Observable<Reviews>{
        var url = `${baseUrl}/reviews`;

        console.log('getReviews(): ' + url);

        return this.httpClient
            .get<Reviews>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getManagerReviews(queryOpts: QueryOptions, employees): Observable<Reviews>{
        var url = `${baseUrl}/reviews/managerall/${employees}`;

        console.log('getManagerReviews(): ' + url);

        return this.httpClient
            .get<Reviews>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getMyReviews(queryOpts: QueryOptions, id): Observable<Reviews>{


        var url = `${baseUrl}/reviews/manager/${id}`;

        console.log('getMyReviews(): ' + url);

        return this.httpClient
            .get<Reviews>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    

    getMyEvaluations(queryOpts: QueryOptions, id): Observable<Reviews>{
        var url = `${baseUrl}/evaluations/evaluator/${id}`;

        console.log('getMyEvaluations(): ' + url);

        return this.httpClient
            .get<Reviews>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    getEvaluatorsNoEvaluation(userid, reviewid): Observable<Reviews>{
        var url = `${baseUrl}/evaluators/employee/${userid}/review/${reviewid}`;

        console.log('getEvaluatorsNoEvaluation(): ' + url);

        return this.httpClient
            .get<Reviews>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    getReview(id): Observable<Review>{
        var url = `${baseUrl}/review/full/${id}`;

        console.log('getReview(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }        


    getReviewByYear(userid, year): Observable<Review>{
        var url = `${baseUrl}/review/employee/${userid}/year/${year}`;

        console.log('getReviewByYear(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }   
    
    getComments(evalid): Observable<Review>{
        var url = `${baseUrl}/comments/${evalid}`;

        console.log('getComments(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }           
    
    addReview(userid, year): Observable<Review>{
        var url = `${baseUrl}/review/employee/${userid}/year/${year}`;

        console.log('addReviewByYear(): ' + url);

        return this.httpClient
            .post<Review>(url, {})
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }        
    
    addEvaluation(row: any): Observable<Review>{
        var url = `${baseUrl}/evaluations`;

        console.log('addEvaluation(): ' + url);

        return this.httpClient
            .post<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    
    
    addComments(row: any): Observable<Review>{
        var url = `${baseUrl}/comments`;

        console.log('addComments(): ' + url);

        return this.httpClient
            .post<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    
    
    addSummary(row: any): Observable<Review>{
        var url = `${baseUrl}/summaryanswers`;

        console.log('addSummaryAnswers(): ' + url);

        return this.httpClient
            .post<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }   
    
    updateReview(reviewid, row): Observable<Review>{
        var url = `${baseUrl}/review/${reviewid}`;
        console.log('updateReview(): ' + url);

        return this.httpClient
            .put<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }   

    updateEvaluation(evalid, row): Observable<Review>{
        var url = `${baseUrl}/evaluation/${evalid}`;
        console.log('updateEvaluation(): ' + url);

        return this.httpClient
            .put<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }      

    updateAnswer(evalid, qid, row): Observable<Review>{
        var url = `${baseUrl}/answers/evaluation/${evalid}/question/${qid}`;
        console.log('updateAnswers(): ' + url);

        return this.httpClient
            .put<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }      

    updateSummary(evalid, qid, row): Observable<Review>{
        var url = `${baseUrl}/summaryanswers/evaluation/${evalid}/question/${qid}`;
        console.log('updateSummaryAnswers(): ' + url);

        return this.httpClient
            .put<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }      
    
    updateComments(evalid, qheader, row): Observable<Review>{
        var url = `${baseUrl}/comments/evaluation/${evalid}/header/${qheader}`;
        console.log('updateComments(): ' + url);

        return this.httpClient
            .put<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }       

    addAnswer(row: any): Observable<Review>{
        var url = `${baseUrl}/answers`;

        console.log('addAnswers(): ' + url);

        return this.httpClient
            .post<Review>(url, row)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }                


    getEvaluation(id): Observable<Review>{
        var url = `${baseUrl}/evaluation/${id}`;

        console.log('getReview(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }   
    
    getEvaluators(userId): Observable<Review>{
        var url = `${baseUrl}/evaluators/employee/${userId}`;

        console.log('getEvaluators(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }         
    
    getEvaluations(userId): Observable<Review>{
        var url = `${baseUrl}/evaluations/employee/${userId}`;

        console.log('getEvaluations(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }            

    getSummaryQuestions(): Observable<Review>{
        var url = `${baseUrl}/summaryquestions`;

        console.log('getSummaryQuestions(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }        

    getSummaryQuestionsAndAnswers(id): Observable<Review>{
        var url = `${baseUrl}/summaryquestionsandanswers/${id}`;

        console.log('getSummaryQuestionsAndAnswers(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }        

    getQuestionCategories(): Observable<Review>{
        var url = `${baseUrl}/questioncategories`;

        console.log('getSummaryQuestionCategories(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    
    
    getQuestionsAndAnswers(id): Observable<Review>{
        var url = `${baseUrl}/questionsandanswers/${id}`;

        console.log('getQuestionsAndAnswers(): ' + url);

        return this.httpClient
            .get<Review>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }   
    
    deleteEvaluators(userid): Observable<Review>{ 
        var url = `${baseUrl}/evaluators/employee/${userid}`;

        console.log('deleteEvaluators(): ' + url);

        return this.httpClient
            .delete<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    } 

    saveEvaluators(userid, evaluatorid): Observable<Review>{ 
        var url = `${baseUrl}/evaluators`;

        console.log('saveEvaluators(): ' + url);

        return this.httpClient
            .post<any>(url, {userid, evaluatorid})
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }     

}