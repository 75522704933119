import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { DataService, ReviewsService, AccountService, NotificationService } from '@app/_services';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';
import { Review } from '@app/_models';
import { ThisReceiver } from '@angular/compiler';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '@app/_components/confirmation/confirmation.component';
import { Router, ActivatedRoute } from '@angular/router';
import {  ChangeDetectorRef } from '@angular/core';
import { environment } from '@environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';

import {
    DialogLayoutDisplay,
    ConfirmBoxInitializer
  } from '@costlydeveloper/ngx-awesome-popup';
import { now } from 'jquery';

@Component({
  selector: 'evaluation-form',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})



export class EvaluationComponent implements OnInit, OnDestroy {
  
  favoriteSeason: string;
  qs: number[] = [1, 2, 3, 4, 5, 6];
  cdRef: ChangeDetectorRef;
  account = this.accountService.accountValue;
  loading: boolean = false;
  currentHeader = '';
  editing: boolean = false;
  self: boolean = false;
  commentCount = 0;
  error: boolean = false;
  intervalId;
  canReset: boolean = false;

  constructor(
   public dataService: DataService,
   public accountService: AccountService,
   private reviewsService: ReviewsService,
   private notificationService: NotificationService,
   public dialog: MatDialog,
   private route: ActivatedRoute,
   private router: Router,
   private cdr: ChangeDetectorRef,
   private _snackBar: MatSnackBar
  ) { }


  autosave() {
    console.log(`Autosaving draft`);
    this.saveEval('Autosaving...');
  }

  public ngOnDestroy() {
         // cancel the interval
         clearInterval(this.intervalId);
  }

  ngOnInit(): void {

    if (localStorage.getItem('evaluation')) {
        this.loadEvaluation(localStorage.getItem('evaluation'));
    };
   
    // is there an order?
    if (this.dataService.evaluation) {
        this.loadEvaluation(this.dataService.evaluation.id);
        localStorage.setItem('evaluation', this.dataService.evaluation.id);

    } else {
/*
        this.canSave = true;
        this.canSubmit = true;
        this.canComplete = false;
        this.neworder = true;
        this.canSelectVendor = true;
        this.showPM = false;
        this.showFinal = false;
        this.canFreightTax = true;


        this.dataService.order = {
            po_nbr: 'not assigned',
            requestdate: Date.now(),
            vendorname: '',
            taxnumber: '',
            phone: '',
            status: 'draft',
            address: '',
            city: '',
            state: '',
            postal: '',
            fulladdress: '',
            approver1: null,
            approver2: null,
            requestorname:  this.accountService.accountValue.user.firstname + " " + this.accountService.accountValue.user.lastname,
            freight: 0,
            subtotal: 0,
            tax: 0,
            total: 0,
            paid: 0,
            pototal: 0,
            taxpercent: .00,
            ponotes: '',
            digitalinventorychecked: 0,
            physicalinventorychecked: 0,
            price: 0,
            whenneeded: null,
            whenplaced: null,
            expectdate: null,
            arrivaldate: null,
            bin: ''
        }
        this.dataService.setShipTo();
   */
    }

    
   
   }

   loadEvaluation(id) {

    // load the order
    this.reviewsService.getEvaluation(id).pipe(
        map(evaluation => {
                this.dataService.evaluation = evaluation;

                if (evaluation.employee === evaluation["evaluator"]) {
                  this.self = true;
                }
                
                // can this be reset?
//                if (evaluation["evaluationstatus"] === "submitted" && this.dataService.evaluation.evaluatorid === this.account.user.id && evaluation["reviewstatus"] != "completed") {
                if ( this.account.user.role === 'admin' || (evaluation["evaluationstatus"] === "submitted" && this.dataService.evaluation.managerid === this.account.user.id && evaluation["reviewstatus"] != "completed")) {
                  this.canReset = true;
                }

                this.reviewsService.getSummaryQuestionsAndAnswers(id).pipe(
                  map(summaryquestions => {
                          this.dataService.summaryquestions = summaryquestions;
                          
                          this.reviewsService.getQuestionCategories().pipe(
                            map(questioncategories => {
                                    this.dataService.questioncategories = questioncategories;

                                    this.reviewsService.getQuestionsAndAnswers(id).pipe(
                                      map(questions => {
                                              this.dataService.questions = questions;

                                              this.reviewsService.getComments(id).pipe(
                                                map(comments => {
                                                        this.dataService.comments = comments;
                                                }),
                                              ).subscribe();          
                                      }),
                                    ).subscribe();          


                            }),
                          ).subscribe();                                  
                  }),
                ).subscribe();            
        }),
    ).subscribe();
  }   

  trackHeaders(index, question) {
    //console.log(question);


    if (this.currentHeader == question.qheader) {
      question.qheader = 'X';
    } else {
        this.currentHeader = question.qheader;

    }
    return question ? question.qheader : undefined;

  }


  showComment(id) {
    if (id==1) {
      this.commentCount = 0;
    }

    if (!this.dataService.questions[id]) {
      this.commentCount++;
      return true;
    }
    if (this.dataService.questions[id] && this.dataService.questions[id].qheader != 'X' && (this.dataService.questions[id].qheader != this.dataService.questions[id-1].qheader)) {
      this.commentCount++;
      return true;

    } else {
      
      return false;
    }

  }

  editEval() {
    
    var autosaveinterval:any = `${environment.autosave}`;
    this.editing = true;


    this.intervalId = setInterval(() => {
      this.autosave();
    }, autosaveinterval * 1000);
  
    

  
  

  }



  saveEval(message) {
    
    var body;

    // save summary
    for (let i = 1; i <= 4; i++) {
      body = 
        { 
          'atext': this.dataService.summaryquestions[i-1].atext
        }
      ;

      this.reviewsService.updateSummary(this.dataService.evaluation.id, i, body).subscribe();
    }
    // save questions
    this.dataService.questions.forEach(q => {
      body = {'answer': q.answer};
      this.reviewsService.updateAnswer(this.dataService.evaluation.id, q.id, body).subscribe();
    });

    // save comments
    var obj;

    obj = document.getElementById("Comment1");
    this.dataService.comments[0].qcomment = obj.value;
    obj = document.getElementById("Comment2");
    this.dataService.comments[1].qcomment = obj.value;
    obj = document.getElementById("Comment3");
    this.dataService.comments[2].qcomment = obj.value;
    obj = document.getElementById("Comment4");
    this.dataService.comments[3].qcomment = obj.value;


    body = {'qcomment': this.dataService.comments[0].qcomment};
    this.reviewsService.updateComments(this.dataService.evaluation.id, 'Inspiring Others', body).subscribe();
    body = {'qcomment': this.dataService.comments[1].qcomment};
    this.reviewsService.updateComments(this.dataService.evaluation.id, 'Driving Results', body).subscribe();
    body = {'qcomment': this.dataService.comments[2].qcomment};
    this.reviewsService.updateComments(this.dataService.evaluation.id, 'Delivering Expertise', body).subscribe();
    body = {'qcomment': this.dataService.comments[3].qcomment};
    this.reviewsService.updateComments(this.dataService.evaluation.id, 'Creating Solutions', body).subscribe();
    
    body = {'overallrating': this.dataService.evaluation.overallrating};
    this.reviewsService.updateEvaluation(this.dataService.evaluation.id, body).subscribe();


    this._snackBar.open(message, null,  {
      duration: 800
    } );


  }

  submitEval() {
    this.dataService.confirmationTitle = 'ALERT';
    this.dataService.confirmationText = 'Are you sure you want to submit this evaluation?';
    this.dataService.dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '400px',
    });
    this.dataService.dialogRef.afterClosed().subscribe(result => {
      if (result) {



        return this.submitEvalGood();
      } else {
        return false;
      }

    });
    return false;
  }


  submitEvalGood() {
    this.editing = false;
    var body;


    if (this.validate()) {

      


      // save summary
      for (let i = 1; i <= 4; i++) {
        body = 
          { 
            'atext': this.dataService.summaryquestions[i-1].atext
          }
        ;

        this.reviewsService.updateSummary(this.dataService.evaluation.id, i, body).subscribe();
      }
      // save questions
      this.dataService.questions.forEach(q => {
        body = {'answer': q.answer};
        this.reviewsService.updateAnswer(this.dataService.evaluation.id, q.id, body).subscribe();
      });

      // save comments
      var obj;

      obj = document.getElementById("Comment1");
      this.dataService.comments[0].qcomment = obj.value;
      obj = document.getElementById("Comment2");
      this.dataService.comments[1].qcomment = obj.value;
      obj = document.getElementById("Comment3");
      this.dataService.comments[2].qcomment = obj.value;
      obj = document.getElementById("Comment4");
      this.dataService.comments[3].qcomment = obj.value;


      body = {'qcomment': this.dataService.comments[0].qcomment};
      this.reviewsService.updateComments(this.dataService.evaluation.id, 'Inspiring Others', body).subscribe();
      body = {'qcomment': this.dataService.comments[1].qcomment};
      this.reviewsService.updateComments(this.dataService.evaluation.id, 'Driving Results', body).subscribe();
      body = {'qcomment': this.dataService.comments[2].qcomment};
      this.reviewsService.updateComments(this.dataService.evaluation.id, 'Delivering Expertise', body).subscribe();
      body = {'qcomment': this.dataService.comments[3].qcomment};
      this.reviewsService.updateComments(this.dataService.evaluation.id, 'Creating Solutions', body).subscribe();

      body = {'status': 'submitted', 'overallrating': this.dataService.evaluation.overallrating};
      this.reviewsService.updateEvaluation(this.dataService.evaluation.id, body).subscribe();

      // send email to manager if this is not the manager

      if (this.account.user.email !== this.dataService.evaluation.manageremail) {
        var mailObj = {
          "to": this.dataService.evaluation.manager,
          "subject": "An evaluation has been submitted",
          "text": `${this.dataService.evaluation.evaluator} has submitted an evaluation.`,
          "html": `${this.dataService.evaluation.evaluator} has submitted an evaluation. FifthColor Employee Evaluation <a href="http://localhost:4200/#/reviews/dashboard">link</a><br><br>Intended recipients: ${this.dataService.evaluation.manageremail}`
        };


        this.notificationService.sendNotification(mailObj).pipe(
          map( async notification => {
              console.log(notification);
          })
        
        ).subscribe();
      }

      this._snackBar.open("The Evaluation has been submitted!", "OK",  {
        duration: 2000
      } );

      // cancel the interval
      clearInterval(this.intervalId);

      this.back();

    } else {
      this.editing = true;
    }


  }

  markComplete() {
    this.dataService.confirmationTitle = 'ALERT';
    this.dataService.confirmationText = 'Are you sure you want to mark this evaluation complete?';
    this.dataService.dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '400px',
    });
    this.dataService.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        return this.markCompleteGood();
      } else {
        return false;
      }

    });
    return false;
  }

  backToDraft() {
    this.dataService.confirmationTitle = 'ALERT';
    this.dataService.confirmationText = 'Are you sure you want to set this evaluation back to draft?';    
    this.dataService.dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '400px',
    });
    this.dataService.dialogRef.afterClosed().subscribe(result => {
      if (result) {
          var body = {'status': 'draft'};

          this.reviewsService.updateEvaluation(this.dataService.evaluation.id, body).subscribe();
          this.back();
        return true;
      } else {
        return false;
      }

    });
    return false;
  }


  markCompleteGood() {
    this.editing = false;
    var body;
    debugger;
    let date = new Date(Date.now());

    // Get year, month, and day part from the date
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = year + "-" + month + "-" + day;
    body = {'status': 'completed', 'evaluationdate': formattedDate};

    this.reviewsService.updateEvaluation(this.dataService.evaluation.id, body).subscribe();
    body = {'status': 'completed'};
      // mark the review as complete
    this.reviewsService.updateReview(this.dataService.evaluation.reviewid, body).subscribe();

      var mailObj = {
        "to": this.dataService.evaluation.employee,
        "subject": "Your manager has completed your evaluation",
        "text": `${this.dataService.evaluation.manager} has completed your evaluation.`,
        "html": `${this.dataService.evaluation.manager} has completed your evaluation. FifthColor Employee Evaluation <a href="http://localhost:4200/#/reviews/dashboard">link</a><br><br>Intended recipients: ${this.dataService.evaluation.employee}`
      };


      this.notificationService.sendNotification(mailObj).pipe(
        map( async notification => {
            console.log(notification);
        })
      
      ).subscribe();

      this._snackBar.open("The Evaluation has been completed!", "OK",  {
        duration: 2000
      } );

      this.back();
  }

  cancelEval() {
    this.editing = false;
    // cancel the interval
    clearInterval(this.intervalId);
  }

  back() {
    this.dataService.evaluation = null;
    localStorage.removeItem('evaluation');

    if (this.account.user.role === 'manager' || this.account.user.role === 'admin') {
      this.router.navigate(['/reviews/dashboard'], { relativeTo: this.route })
    } else {
      this.router.navigate(['/reviews/dashboard'], { relativeTo: this.route })
    }
  }

  validate() {
    var error = false;

    // verify summary questions completed
    for (let i = 1; i <= 4; i++) {
      if (!this.dataService.summaryquestions[i-1].atext || this.dataService.summaryquestions[i-1].atext.trim() === "") {
        error = true;
      }

    }


     // validate questions
     this.dataService.questions.forEach(q => {
        if (!q.answer) {
          error = true;
        }
    });

    var obj; 
    // validate comments
    obj = document.getElementById("Comment1");
    if (obj.value.trim() === '') {
      error = true;
    }
    
    obj = document.getElementById("Comment2");
    if (obj.value.trim() === '') {
      error = true;
    }
    obj = document.getElementById("Comment3");
    if (obj.value.trim() === '') {
      error = true;
    }
    obj = document.getElementById("Comment4");
    if (obj.value.trim() === '') {
      error = true;
    }


    if (error) {
      this._snackBar.open("Validation failed - please complete all fields", "OK",  {
        duration: 10000
      } );
      return false;
    }

    return true;
  }


}
