import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { QueryOptions } from '@app/_models/query-opts';


@Injectable()
export class DataService {

    public reviews: any; 
    public reviews2: any; 

    public review: any;
    public evaluation: any;
    public questions: any;
    public summaryquestions: any;

    public evaluators: any;
    public evaluations: any;
    public questioncategories: any;
    public comments: any;

    public itemslist: any;
    public vendorslist: any;
    public noteslist: any;
    public item: any;
    public selectedVendor: any;
    public selecteditem: any;
    public selecteditemid: any;
    public selecteditemdescription: any;
    public startDate: any;
    public endDate: any;
    public showSimpleDatePicker: boolean;
    public selectedDates: { startDate: Moment, endDate: Moment };
    public dialogRef: any;
    public approver1 = 1;
    public approver2 = 3;
    public isFinal = false;
    public canComplete = false;
    public canAdd = false;

    public confirmationText = 'Text';
    public confirmationTitle = 'Title';


    
    queryOptions: QueryOptions = new QueryOptions();
    
    public constructor( ) {


    }



}