import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { DataService, ReviewsService, AccountService, NotificationService } from '@app/_services';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';
import { Review } from '@app/_models';
import { ThisReceiver } from '@angular/compiler';
import { MatDialog } from '@angular/material/dialog';
import { PicklistComponent } from '@app/_components/picklist/picklist.component';
import { Router, ActivatedRoute } from '@angular/router';
import {  ChangeDetectorRef } from '@angular/core';
import {MatCheckboxChange, MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import { environment } from '@environments/environment';
import moment, { Moment } from 'moment';

import {
    DialogLayoutDisplay,
    ConfirmBoxInitializer
  } from '@costlydeveloper/ngx-awesome-popup';
import { now } from 'jquery';

@Component({
  selector: 'app-company-info-form',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})

export class ReviewComponent implements OnInit {
  cdRef: ChangeDetectorRef;
  account = this.accountService.accountValue;
  loading: boolean = false;
  
  constructor(
   public dataService: DataService,
   public accountService: AccountService,
   private reviewsService: ReviewsService,
   private notificationService: NotificationService,
   public dialog: MatDialog,
   private route: ActivatedRoute,
   private router: Router,
   private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    
    if (localStorage.getItem('review')) {
        this.loadReview(localStorage.getItem('review'));
    };
   
    // is there an order?
    if (this.dataService.review) {
        this.loadReview(this.dataService.review.id);
        localStorage.setItem('review', this.dataService.review.id);

    } else {

    }

    
   
   }

   loadReview(id) {

    // load the order
    this.reviewsService.getReview(id).pipe(
        map(review => {
                this.dataService.review = review;

                
            
        }),
    ).subscribe();
  }   

  openEvaluation(evalu) {
    debugger;

    // check the status and who is opening
    if (this.account.user.role === 'admin' || (this.account.user.role === 'manager' && evalu.status === 'submitted') || evalu.evaluator === this.account.user.firstname + ' ' + this.account.user.lastname) {

      this.reviewsService.getEvaluation(evalu.id).pipe(
        map(evaluation => {
          this.dataService.evaluation = evaluation;
          localStorage.setItem('evaluation', this.dataService.evaluation.id);
          this.router.navigate(['/reviews/evaluation'], { relativeTo: this.route })          
        }),
      ).subscribe();
    }


  }

}
