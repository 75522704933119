import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from '@app/_services';

@Component({
    selector: 'confirmation',
    templateUrl: 'confirmation.component.html',
  })
  export class ConfirmationComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmationComponent>,
        public dataService: DataService
    ) {}
    
    onClick(value): void {
        this.dialogRef.close(value);
    }

      

}