import { Component, OnInit , Input} from '@angular/core';
import { ReviewsService } from '../../_services/reviews.service';
import { AccountService } from '@app/_services';
import { DataService } from '@app/_services';
import { Reviews } from '../../_models';
import { QueryOptions } from '@app/_models/query-opts';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';

@Component({ 
    templateUrl: 'dashboard.component.html',
    providers: [ReviewsService]
})


export class DashboardComponent implements OnInit {
    itemsperpage: number;
    currentpage: number;
    totalrecords: number;
    pageSize: number = 5;
    page: number = 1;
    totalPages: number;

    itemsperpage2: number;
    currentpage2: number;
    totalrecords2: number;
    pageSize2: number = 5;
    page2: number = 1;
    totalPages2: number;

    account = this.accountService.accountValue;
    reviews: Reviews;
    reviews2: Reviews;
    title: string;
    timestamp: string;
    datestamp: string;
    reviews$: Observable<Reviews>;
    viewtitle = "My Reviews";
    viewtitle2 = "My Evaluations";
    isAdmin: boolean;
    isManager: boolean;



    queryOptions: QueryOptions = new QueryOptions();
    queryOptions2: QueryOptions = new QueryOptions();

    

    constructor(private accountService: AccountService,
            public dataService: DataService,
            private reviewsService: ReviewsService,
            private route: ActivatedRoute,
            private router: Router
        ) {
        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 5;
        this.queryOptions.sortDirection = 'desc';

        this.queryOptions2.page = 1;
        this.queryOptions2.pageSize = 5;
        this.queryOptions2.sortDirection = 'desc';
        
     
    }

   

    ngOnInit(): void {
        this.itemsperpage = 5;
        this.currentpage = 1;

        this.itemsperpage2 = 5;
        this.currentpage2 = 1;

        localStorage.removeItem('review'); 
        localStorage.removeItem('evaluation'); 
        this.isAdmin = this.account.user.role === 'admin';
        this.isManager = this.account.user.role === 'manager';

       
        var term: string;
        var objValue: string;

        this.loadReviews();
        this.loadEvaluations();

    }




    ngOnDestroy(): void {        

    }

    pageSizeChange(event) {
        this.pageSize = event;
      }
    
    handlePageChange(event) {
    this.queryOptions.page = event;
    this.queryOptions.pageSize = this.pageSize;
    this.currentpage = event;
    // this.refreshLeads(this.queryOptions.sortOrder);  
    }

    pageSizeChange2(event) {
        this.pageSize2 = event;
      }
    
    handlePageChange2(event) {
    this.queryOptions2.page = event;
    this.queryOptions2.pageSize = this.pageSize2;
    this.currentpage2 = event;
    // this.refreshLeads(this.queryOptions.sortOrder);  
    }

    loadReviews() {
        this.queryOptions.sortOrder = 'year';
        this.queryOptions.sortDirection = 'desc';
        this.queryOptions.pageSize = 1000000;

        // need to possibly merge reviews for the manager and their own reviews

        this.reviewsService.getMyReviews(this.queryOptions, this.account.user.id).pipe(
            map(reviews => {
                    this.dataService.reviews = reviews;
                    this.totalrecords = this.dataService.reviews.length;
            }),
        ).subscribe();



        
    }

    loadEvaluations() {
        this.queryOptions.sortOrder = 'year';
        this.queryOptions.sortDirection = 'desc';
        this.queryOptions.pageSize = 1000000;

        //this.reviewsService.getReviews(this.queryOptions, this.account.user.id).pipe(
        this.reviewsService.getMyEvaluations(this.queryOptions2, this.account.user.id).pipe(
            map(evaluations => {
                    this.dataService.evaluations = evaluations;
                    this.totalrecords2 = this.dataService.evaluations.length;
            }),
        ).subscribe();
    }

    /*
    loadOrders() {
        this.queryOptions.sortOrder = 'requestdate';
        this.queryOptions.sortDirection = 'desc';
        this.queryOptions.pageSize = 1000000;

        this.queryOptions2.sortOrder = 'requestdate';
        this.queryOptions2.sortDirection = 'desc';
        this.queryOptions2.pageSize = 1000000;

        // is this a requestor?
        if (this.account.user.role === 'requestor') {
            this.canAdd = true;
            this.viewtitle = 'My Current Purchase Orders';
            this.ordersService.getMyActiveOrders(this.queryOptions, this.account.user.id).pipe(
                map(orders => {
                        this.dataService.orders = orders["orders"];
                        this.totalrecords = this.dataService.orders.length;
                }),
            ).subscribe();
            this.viewtitle2 = 'My Approved Purchase Orders';
            this.ordersService.getMyApprovedOrders(this.queryOptions, this.account.user.id).pipe(
                map(orders2 => {
                        this.dataService.orders2 = orders2["orders"];
                        this.totalrecords2= this.dataService.orders2.length;
                }),
            ).subscribe();            
        } else {
            this.canAdd = false;
            
            if (this.isReceiver) {
                this.viewtitle = 'Approved Purchased Orders';
                this.ordersService.getApprovedPurchaseOrders(this.queryOptions).pipe(
                    map(orders => {
                            this.dataService.orders = orders["orders"];
                            this.totalrecords = this.dataService.orders.length;
                    }),
                ).subscribe();

                this.viewtitle2 = 'Completed Purchase Orders';
                this.ordersService.getCompletedPurchaseOrders(this.queryOptions ).pipe(
                    map(orders => {
                            this.dataService.orders2 = orders["orders"];
                            this.totalrecords2 = this.dataService.orders.length;
                    }),
                ).subscribe();   
            } else if (this.isPM) {
                this.viewtitle = 'Purchase Orders Awaiting My Approval';
                this.ordersService.getMyOrdersForApproval(this.queryOptions).pipe(
                    map(orders => {
                            this.dataService.orders = orders["orders"];
                            this.totalrecords = this.dataService.orders.length;
                    }),
                ).subscribe();



                this.viewtitle2 = 'My Orders';
                this.ordersService.getMyOrders(this.queryOptions, this.account.user.id ).pipe(
                    map(orders => {
                            this.dataService.orders2 = orders["orders"];
                            this.totalrecords2 = this.dataService.orders.length;
                    }),
                ).subscribe();                
            } else {
                this.viewtitle = 'Purchase Orders Awaiting My Approval';
                this.ordersService.getMyOrdersForFinalApproval(this.queryOptions ).pipe(
                    map(orders => {
                            this.dataService.orders = orders["orders"];
                    }),
                ).subscribe();

                this.viewtitle2 = 'All Purchase Orders';
                this.ordersService.getAllPurchaseOrders(this.queryOptions ).pipe(
                //    this.ordersService.getMyPreviousFinalApprovals(this.queryOptions, this.account.user.id ).pipe(                    
                    map(orders => {
                            this.dataService.orders2 = orders["orders"];
                    }),
                ).subscribe();           

            }
        }
    
      
      }
      */
      sortLeads(sortOrder) {
      }

      openReview(review) {
        this.dataService.review = review;
        this.router.navigate(['/reviews/review'], { relativeTo: this.route })
      }

      openEvaluation(evaluation) {
        this.dataService.evaluation = evaluation;
        localStorage.setItem('evaluation', evaluation.id);
        this.router.navigate(['/reviews/evaluation'], { relativeTo: this.route })

        
      }

      openEvaluationXXX(evalu) {
        debugger;
    
        this.reviewsService.getEvaluation(evalu.id).pipe(
          map(evaluation => {
            this.dataService.evaluation = evaluation;
            localStorage.setItem('evaluation', this.dataService.evaluation.id);
            this.router.navigate(['/reviews/evaluation'], { relativeTo: this.route })          
          }),
        ).subscribe();
    
    
      }


/*
    sortLeads(sortOrder) {
        // is this a new sort order?
        if (this.queryOptions.sortOrder === sortOrder) {
            if (this.queryOptions.sortDirection === 'asc') {
                this.queryOptions.sortDirection = 'desc';
            } else {
                this.queryOptions.sortDirection = 'asc';
            }
        } else {
            this.queryOptions.sortDirection = 'desc';
        }
        this.queryOptions.sortOrder = sortOrder;

        $(`#company_employees_sort`).removeClass('btn-sort');
        $(`#company_revenue_sort`).removeClass('btn-sort');
        $(`#page_count_sort`).removeClass('btn-sort');
        $(`#session_count_sort`).removeClass('btn-sort');

        $(`#company_employees_sort`).removeClass('btn-sort-asc');
        $(`#company_revenue_sort`).removeClass('btn-sort-asc');
        $(`#page_count_sort`).removeClass('btn-sort-asc');
        $(`#session_count_sort`).removeClass('btn-sort-asc');

        $(`#company_employees_sort`).addClass('btn-hollow');
        $(`#company_revenue_sort`).addClass('btn-hollow');
        $(`#page_count_sort`).addClass('btn-hollow');
        $(`#session_count_sort`).addClass('btn-hollow');

        if (this.queryOptions.sortDirection === 'desc') {
            $(`#${sortOrder}_sort`).addClass('btn-sort');
            if (sortOrder==='company_employees' || sortOrder==='company_revenue') {
              this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) < parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1)
            } else {
              this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] < b[sortOrder]) ? 1 : -1)
            }
    
        } else {
            $(`#${sortOrder}_sort`).addClass('btn-sort-asc');
            if (sortOrder==='company_employees' || sortOrder==='company_revenue') {
              this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) > parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1)
            } else {
              this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] > b[sortOrder]) ? 1 : -1)
            }
        }
        $(`#${sortOrder}_sort`).removeClass('btn-hollow');
    


    }
*/


     
}